import { useState, useMemo } from "react";
import { logEvent } from "firebase/analytics";
import Chart from "react-apexcharts";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  Grid,
  Accordion, AccordionSummary, AccordionDetails,
  TextField, Button,
  Card, CardContent, CardActions,
  LinearProgress,
  Typography, Skeleton
} from "@mui/material";
import {
  ExpandMore as ExpandMoreIcon,
  PlayCircleFilled as PlayCircleFilledIcon,
  ArrowDownward as ArrowDownwardIcon
} from "@mui/icons-material";
import {
  getCurrentUser
} from "../firebase/Authentication-Google";
import { analytics } from "../firebase/Analytics";
import { fetchPost } from "../utils/FetchFunctions";
import { gasFetchGet } from "../utils/FetchGas";
import { useStateContext } from "../utils/StateContext";

type Output = {
  label: string;
  score: number;
}

function TextClassification(): JSX.Element {
  const {
    createMessage,
    isRunning,
    setIsRunning,
    isSignIn,
    points,
    setPoints,
    setUpdatedDate
  } = useStateContext();

  // 環境変数から文章解析で消費するポイントを取得
  const textClassificationPoints = Number(process.env.REACT_APP_textClassificationPoints);
  // 環境変数から最大文字数を取得
  const maxTextLength = Number(process.env.REACT_APP_maxTextLength);
  // 使い方のリスト
  const helpDetailsList = [
    `解析する文章を入力してね！文字数は${maxTextLength}文字までだよ！`,
    "「解析開始」ボタンを押してね！",
    "文章解析が終わると、「解析開始」ボタンの下に解析された結果が表示されるよ！文章の感情の割合が表示されるよ！"
  ];
  // 文章解析の進捗に表示するメッセージのリスト
  const progressMessageList = [
    "",
    "翻訳中...",
    "文章解析中...",
    "解析完了！"
  ];

  // 使い方を展開するか
  const [helpAccordion, setHelpAccordion] = useState<boolean>(false);
  // 文章
  const [input, setInput] = useState<string>("");
  // 文章解析の結果
  const [output, setOutput] = useState<Output[] | undefined>(undefined);
  // 文章解析の進捗
  const [progress, setProgress] = useState<number>(0);

  // 文章解析後のポイント
  const newPoints = useMemo(() => {
    return points - textClassificationPoints;
  }, [points, textClassificationPoints]);

  const start = async () => {
    setIsRunning(true);
    setProgress(0);
    createMessage("送信中...", "info");
    logEvent(analytics, "文章解析の「送信」ボタン押下", {
      app: "maitake-ai",
      module: "pages/ImageClassification.tsx",
      function: "start"
    });

    // 現在サインインしているユーザを取得
    const currentUser = getCurrentUser();
    if (!currentUser) {
      createMessage("サインインしていないよ。", "error");
      setProgress(0);
      setIsRunning(false);
      return;
    }

    // 解析結果をリセット
    setOutput(undefined);

    // サインインしているユーザのuidを取得
    const uid = currentUser.uid;

    // 文章を翻訳
    setProgress(1);
    const translateInput = await translate(input);
    if (!translateInput) {
      createMessage("翻訳失敗。", "error");
      setProgress(0);
      setIsRunning(false);
      return;
    }

    // 文章解析
    setProgress(2);
    const data = await fetchPost("textClassification", uid, translateInput);

    if (data === undefined) {
      createMessage("送信失敗。", "error");
      setProgress(0);
      setIsRunning(false);
      return;
    }
    setPoints(data.points);
    setUpdatedDate(data.updatedDate);
    setOutput(data.output);
    createMessage(data.message, (data.result ? "success" : "error"));
    setProgress(3);
    setIsRunning(false);
  };

  const translate = async (text: string): Promise<string> => {
    // 画像解析結果を英語から日本語に翻訳
    const data = await gasFetchGet(text, "ja", "en");

    if (data === undefined) {
      createMessage("翻訳失敗。", "error");
      return "";
    }
    createMessage(data.message, (data.result ? "success" : "error"));
    return data.output;
  }

  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const series = output
    ? output.map(item => parseFloat((item.score * 100).toFixed(1)))
    : [];
  const options = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    theme: {
      mode: prefersDarkMode ? "dark" : "light" as "dark" | "light",
    },
    labels: output
      ? output.map(item => item.label)
      : [],
    tooltip: {
      y: {
        formatter: function (val: number) {
          return val + "%"
        }
      }
    }
  };

  return (
    <div>
      <Typography
        sx={{ mt: 2 }}
      >
        {`消費ポイント：${textClassificationPoints} pt`}
      </Typography>

      <Typography
        variant="h6"
        sx={{
          mt: 2,
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center"
        }}
      >
        {`文章の感情解析`}
      </Typography>

      <Accordion
        expanded={helpAccordion}
        onChange={() => setHelpAccordion(!helpAccordion)}
        sx={{
          mt: 2,
          textAlign: "start"
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          {"使い方"}
        </AccordionSummary>
        <AccordionDetails>
          <ol>
            {helpDetailsList.map((helpDetails, index) => (
              <li key={index}>
                {helpDetails}
              </li>
            ))}
          </ol>
        </AccordionDetails>
      </Accordion>

      <Grid container
        spacing={2}
        sx={{
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <Grid item xs={12}
          sx={{ mt: 2 }}
        >
          <Card>
            <CardContent>
              <Typography
                variant="body2"
                sx={{
                  textAlign: "end"
                }}
              >
                {`${input.length} / ${maxTextLength}文字`}
              </Typography>

              <TextField
                label={"解析する文章"}
                value={input}
                onChange={(e) => setInput(e.target.value)}
                required
                disabled={
                  isRunning
                  || (!isSignIn)
                }
                error={input.length > maxTextLength}
                variant="outlined"
                multiline
                rows={9}
                fullWidth
                sx={{ mt: 1 }}
              />
            </CardContent>

            <CardActions sx={{ justifyContent: "center" }}>
              <Button
                onClick={start}
                disabled={isRunning
                  || (!isSignIn)
                  || (!input)
                  || (input.length > maxTextLength)
                  || (newPoints < 0)
                }
                startIcon={<PlayCircleFilledIcon />}
                fullWidth
                variant="contained"
                size="large"
                color="primary"
              >
                {`解析開始`}
              </Button>
            </CardActions>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <LinearProgress variant="determinate"
            value={(progress / (progressMessageList.length - 1)) * 100}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="caption">
            {progressMessageList[progress]}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <ArrowDownwardIcon />
        </Grid>

        <Grid item xs={12}>
          {(output && output.length > 0) ?
            <Chart series={series} options={options} type="pie" />
            :
            <Skeleton
              variant="rectangular"
              height={240}
              animation={isRunning ? "wave" : false}
            />
          }
        </Grid>
      </Grid>
    </div>
  );
}

export default TextClassification;
