import { logEvent } from "firebase/analytics";
import {
  Tooltip,
  IconButton
} from "@mui/material";
import {
  Logout as LogoutIcon
} from "@mui/icons-material";
import {
  signOutGoogle
} from "../firebase/Authentication-Google";
import { analytics } from "../firebase/Analytics";
import { useStateContext } from "../utils/StateContext";

function SignOut(): JSX.Element {
  const {
    createMessage,
    isRunning,
    setIsRunning,
    isSignIn,
    setIsSignIn
  } = useStateContext();

  const signOut = async (): Promise<void> => {
    setIsRunning(true);
    createMessage("サインアウト中...", "info");
    logEvent(analytics, "「サインアウト」ボタン押下", {
      app: "maitake-home",
      module: "components/SignOut.tsx",
      function: "signOut"
    });

    // Googleアカウントでサインアウト
    const isSignOut = await signOutGoogle();
    if (!isSignOut) {
      createMessage("サインアウト失敗。", "error");
      setIsRunning(false);
      return;
    }

    setIsSignIn(false);

    createMessage("サインアウト成功！", "success");
    setIsRunning(false);
  }

  return (
    <Tooltip arrow
      title="サインアウト"
    >
      <IconButton
        onClick={signOut}
        disabled={isRunning
          || (!isSignIn)
        }
        size="large"
        color="secondary"
        edge="end"
      >
        <LogoutIcon />
      </IconButton>
    </Tooltip>
  );
}

export default SignOut;
