import {
  Paper, BottomNavigation, BottomNavigationAction
} from "@mui/material";
import { navigationList } from "../utils/Config";

interface NavigationProps {
  navigation: number;
  setNavigation: (navigation: number) => void;
}

function Navigation({
  navigation,
  setNavigation
}: NavigationProps): JSX.Element {
  return (
    <Paper sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }} elevation={3}>
      <BottomNavigation
        showLabels
        value={navigation}
        onChange={(event, newValue) => {
          setNavigation(newValue);
        }}
      >
        {navigationList.map((navigation: any) => (
          <BottomNavigationAction
            key={navigation.label}
            label={navigation.label}
            icon={navigation.icon}
          />
        ))}
      </BottomNavigation>
    </Paper>
  );
}

export default Navigation;
