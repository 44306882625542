import { useState, useMemo } from "react";
import { logEvent } from "firebase/analytics";
import Chart from "react-apexcharts";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  Grid,
  Accordion, AccordionSummary, AccordionDetails,
  Input, Button,
  Card, CardContent, CardMedia, CardActions,
  LinearProgress,
  Typography, Skeleton
} from "@mui/material";
import {
  ExpandMore as ExpandMoreIcon,
  Image as ImageIcon,
  PlayCircleFilled as PlayCircleFilledIcon,
  ArrowDownward as ArrowDownwardIcon
} from "@mui/icons-material";
import {
  getCurrentUser
} from "../firebase/Authentication-Google";
import { analytics } from "../firebase/Analytics";
import { supabase } from "../supabase/Config";
import { fetchPost } from "../utils/FetchFunctions";
import { gasFetchGet } from "../utils/FetchGas";
import { useStateContext } from "../utils/StateContext";

type Output = {
  label: string;
  score: number;
}

function ImageClassification(): JSX.Element {
  const {
    createMessage,
    isRunning,
    setIsRunning,
    isSignIn,
    points,
    setPoints,
    setUpdatedDate
  } = useStateContext();

  // 環境変数から画像解析で消費するポイントを取得
  const imageClassificationPoints = Number(process.env.REACT_APP_imageClassificationPoints);
  // 環境変数から画像ファイルの最大サイズを取得
  const maxImageFileSize = Number(process.env.REACT_APP_maxImageFileSize);
  // 使い方のリスト
  const helpDetailsList = [
    `「アップロード」ボタンを押して、画像をアップロードしてね！ファイルサイズは${maxImageFileSize}MBまでだよ！`,
    "正しい画像がアップロードされているか確認してね！",
    "「解析開始」ボタンを押してね！",
    "画像解析が終わると、「解析開始」ボタンの下に解析された結果が表示されるよ！画像に写る物体の名前が表示されるよ！"
  ];
  // 画像解析の進捗に表示するメッセージのリスト
  const progressMessageList = [
    "",
    "画像をストレージに保存中...",
    "画像解析中...",
    "画像をストレージから削除中...",
    "翻訳中...",
    "解析完了！"
  ];

  // 使い方を展開するか
  const [helpAccordion, setHelpAccordion] = useState<boolean>(false);
  // 画像ファイル
  const [imageFile, setImageFile] = useState<File | undefined>(undefined);
  // 画像解析の結果
  const [output, setOutput] = useState<Output[] | undefined>(undefined);
  // 画像解析で最も可能性の高かった物体の名前の日本語
  const [outputName, setOutputName] = useState<string>("");
  // 画像解析の進捗
  const [progress, setProgress] = useState<number>(0);
  // 翻訳処理中か
  const [isRunningTranslate, setIsRunningTranslate] = useState<boolean>(false);

  // アップロードされた画像を画面に表示するためのURL
  const imageFileUrl = useMemo(() => {
    if (imageFile) {
      return window.URL.createObjectURL(imageFile);
    } else {
      return "";
    }
  }, [imageFile]);

  // 画像解析後のポイント
  const newPoints = useMemo(() => {
    return points - imageClassificationPoints;
  }, [points, imageClassificationPoints]);

  const onChangeFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    setProgress(0);
    const files = e.target.files;
    if (files && files[0]) {
      const newFile = files[0];
      // 最大ファイルサイズ
      const maxFileSize = maxImageFileSize * 1024 * 1024;
      // 最大ファイルサイズを超えていないか確認
      if (newFile.size > maxFileSize) {
        createMessage(`${maxImageFileSize}MB以下のファイルを選択してください。`, "error");
        setImageFile(undefined);
      } else {
        setImageFile(newFile);
      }
    } else {
      setImageFile(undefined);
    }
  };

  const start = async () => {
    setIsRunning(true);
    setProgress(0);
    createMessage("送信中...", "info");
    logEvent(analytics, "画像解析の「送信」ボタン押下", {
      app: "maitake-ai",
      module: "pages/ImageClassification.tsx",
      function: "start"
    });

    // 現在サインインしているユーザを取得
    const currentUser = getCurrentUser();
    if (!currentUser) {
      createMessage("サインインしていないよ。", "error");
      setProgress(0);
      setIsRunning(false);
      return;
    }

    // 解析結果をリセット
    setOutput(undefined);
    setOutputName("");

    // サインインしているユーザのuidを取得
    const uid = currentUser.uid;

    // 画像をSupabaseのStorageにアップロード
    setProgress(1);
    const insertResult = await insertImage(uid);
    if (!insertResult) {
      await deleteImage(uid);

      createMessage("画像アップロード失敗。", "error");
      setProgress(0);
      setIsRunning(false);
      return;
    }

    // 画像解析
    setProgress(2);
    const data = await fetchPost("imageClassification", uid, "");

    // 画像をSupabaseのStorageから削除
    setProgress(3);
    await deleteImage(uid);

    if (data === undefined) {
      createMessage("送信失敗。", "error");
      setProgress(0);
      setIsRunning(false);
      return;
    }
    setPoints(data.points);
    setUpdatedDate(data.updatedDate);
    setOutput(data.output);
    createMessage(data.message, (data.result ? "success" : "error"));
    setIsRunning(false);

    if (!data.result) {
      setProgress(0);
      return;
    }

    // 画像解析結果を英語から日本語に翻訳
    setProgress(4);
    const newOutputName = await translate(data.output[0].label);
    setOutputName(newOutputName);
    setProgress(5);
  };

  const insertImage = async (uid: string): Promise<boolean> => {
    if (!imageFile) {
      return false;
    }

    // 画像をSupabaseのStorageにアップロード
    const { error } = await supabase.storage
      .from("maitake-ai")
      .upload(uid, imageFile, {
        upsert: true,
      });

    if (error) {
      return false;
    }
    return true;
  }

  const deleteImage = async (uid: string): Promise<void> => {
    // 画像をSupabaseのStorageから削除
    await supabase.storage
      .from("maitake-ai")
      .remove([uid]);
  }

  const translate = async (text: string): Promise<string> => {
    setIsRunningTranslate(true);
    // 画像解析結果を英語から日本語に翻訳
    const data = await gasFetchGet(text, "en", "ja");

    if (data === undefined) {
      createMessage("翻訳失敗。", "error");
      setIsRunningTranslate(false);
      return "";
    }
    createMessage(data.message, (data.result ? "success" : "error"));
    setIsRunningTranslate(false);
    return data.output;
  }

  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const series = [
    {
      name: "類似度",
      data: output
        ? output.map(item => parseFloat((item.score * 100).toFixed(2)))
        : [],
    },
  ];
  const options = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    theme: {
      mode: prefersDarkMode ? "dark" : "light" as "dark" | "light",
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        horizontal: true,
      }
    },
    xaxis: {
      categories: output
        ? output.map(item => item.label)
        : [],
      max: 100,
      min: 0,
      title: {
        text: "類似度 (%)"
      },
    },
    grid: {
      strokeDashArray: 4,
      xaxis: {
        lines: {
          show: true
        }
      }
    },
    tooltip: {
      y: {
        formatter: function (val: number) {
          return val + "%"
        }
      }
    }
  };

  return (
    <div>
      <Typography
        sx={{ mt: 2 }}
      >
        {`消費ポイント：${imageClassificationPoints} pt`}
      </Typography>

      <Typography
      variant="h6"
        sx={{
          mt: 2,
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center"
         }}
      >
        {`画像の物体解析`}
      </Typography>

      <Accordion
        expanded={helpAccordion}
        onChange={() => setHelpAccordion(!helpAccordion)}
        sx={{
          mt: 2,
          textAlign: "start"
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          {"使い方"}
        </AccordionSummary>
        <AccordionDetails>
          <ol>
            {helpDetailsList.map((helpDetails, index) => (
              <li key={index}>
                {helpDetails}
              </li>
            ))}
          </ol>
        </AccordionDetails>
      </Accordion>

      <Grid container
        spacing={2}
        sx={{
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <Grid item xs={12}
          sx={{ mt: 2 }}
        >
          <Card>
            <CardContent>
              <label htmlFor="imageFile">
                <Input
                  id="imageFile"
                  type="file"
                  onChange={onChangeFile}
                  required
                  disabled={
                    isRunning
                    || (!isSignIn)
                  }
                  fullWidth
                  disableUnderline
                  inputProps={{
                    accept: "image/*",
                    style: { display: "none" }
                  }}
                />
                <Button
                  component="span"
                  disabled={isRunning
                    || (!isSignIn)
                  }
                  startIcon={<ImageIcon />}
                  fullWidth
                  variant="outlined"
                  size="large"
                  color="primary"
                >
                  {"アップロード"}
                </Button>
              </label>
            </CardContent>

            {imageFileUrl ?
              <CardMedia
                component="img"
                image={imageFileUrl}
                sx={{
                  height: "100%",
                  objectFit: "contain"
                }}
              />
              :
              <Skeleton
                variant="rectangular"
                height={240}
                animation={false}
                sx={{ mt: 2 }}
              />
            }

            <CardActions sx={{ justifyContent: "center" }}>
              <Button
                onClick={start}
                disabled={isRunning
                  || (!isSignIn)
                  || (!imageFile)
                  || (newPoints < 0)
                }
                startIcon={<PlayCircleFilledIcon />}
                fullWidth
                variant="contained"
                size="large"
                color="primary"
              >
                {`解析開始`}
              </Button>
            </CardActions>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <LinearProgress variant="determinate"
            value={(progress / (progressMessageList.length - 1)) * 100}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="caption">
            {progressMessageList[progress]}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <ArrowDownwardIcon />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h5">
            {outputName ?
              outputName
              :
              <Skeleton
                variant="rectangular"
                animation={(isRunning || isRunningTranslate) ? "wave" : false}
              />
            }
          </Typography>
        </Grid>

        <Grid item xs={12}>
          {(output && output.length > 0) ?
            <Chart series={series} options={options} type="bar" />
            :
            <Skeleton
              variant="rectangular"
              height={240}
              animation={isRunning ? "wave" : false}
            />
          }
        </Grid>
      </Grid>
    </div>
  );
}

export default ImageClassification;
